import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";
import Facebook from "../../images/facebook.png";
import LinkedIn from "../../images/linkedIn.png";
import Instagram from "../../images/instagram.png";
import "./Footer.css";

const FooterComponent = () => (
  <div class="footer" id="footer">
    <div class="grid-container">
      <div class="grid-info">
        <h2> Stichting Maya Universe Academy Netherlands</h2>
        <p>Haarlemmerweg 79C</p>
        <p>1051KS Amsterdam Nederland </p>
        <p>stichting@mua-nl.org</p>
        <p>NL34 RABO 0311 6538 47</p>
        <a>RSIN – 856335733</a>
        <br />
        <a>KVK – 85963504</a>
        <br />
        <br />
        <a
          href="https://docdro.id/M1wac4y"
          target="__blank"
        >
          <FormattedMessage
            id="remunerationPolicy"
            defaultMessage="Beloningsbeleid"
          />
        </a>
        <br />
        <br />

        <a
          href="https://drive.google.com/file/d/1_l73TCZ1Ree3aw9jR3ET5NUTsNrApAIp/view?usp=sharing"
          target="__blank"
        >
          <FormattedMessage id="annualStatement" defaultMessage="Jaaropgave" />{" "}
          2023
        </a>
        <br />
        <div style={{ display: "flex", flexDirection: "row", width: "50px" }}>
          <a href="https://www.facebook.com/muanetherlands/" target="__blank">
            <img
              src={Facebook}
              style={{
                width: "35px",
                height: "35px",
                marginLeft: "0px",
              }}
            />
          </a>{" "}
          <a
            href="https://www.linkedin.com/company/maya-universe-academy-netherlands"
            target="__blank"
          >
            <img
              src={LinkedIn}
              style={{ width: "35px", height: "35px", marginLeft: "10px" }}
            />
          </a>
          <a href="https://www.instagram.com/friendsofmaya/" target="__blank">
            <img
              src={Instagram}
              style={{ width: "35px", height: "35px", marginLeft: "10px" }}
            />
          </a>
        </div>
      </div>
      <div class="grid-contact">
        <h2>Meike Schoones</h2>
        <p>
          <FormattedMessage id="chairman-new" defaultMessage="Voorzitter" />
        </p>
        <p>meike@mua-nl.org</p>
      </div>
      <div class="grid-contact">
        <h2>Rikke van der Veen</h2>
        <p>
          <FormattedMessage id="chairman" defaultMessage="Voorzitter" />
        </p>
        <p>rikke@mua-nl.org</p>
      </div>
      <div class="grid-contact">
        <h2>Kim Brolsma</h2>
        <p>
          {" "}
          <FormattedMessage id="treasurer" defaultMessage="PenningMeester" />
        </p>
        <p>kim@mua-nl.org</p>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  loggedIn: !!state.currentUser,
});

export default FooterComponent;
