import React, { useState } from "react";
import styled from "styled-components";
import { FormattedMessage } from "gatsby-plugin-intl";
import addToMailchimp from "gatsby-plugin-mailchimp";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const onSubmit = () => {
    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
      .then((data) => {
        if (
          data.result === "success" &&
          data.msg === "Thank you for subscribing!"
        ) {
          setSuccess(true);
          setEmail("");
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  };
  return (
    <Container>
      <InnerContainer>
        <Title>
          <FormattedMessage
            id="stayUpdated"
            defaultMessage="Blijf op de hoogte via de nieuwsbrief"
          />
        </Title>
        <InputContainer>
          <StyledInput
            value={email}
            onChange={(e) => {
              console.log(e);
              setEmail(e.target ? e.target.value : "");
            }}
          />
          <StyledButton onClick={() => onSubmit()}>
            <FormattedMessage id="subscribe" defaultMessage="Subscribe" />
          </StyledButton>
        </InputContainer>
        {success && <Success>Thanks for signing up!</Success>}
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 40px;
  background-color: #fef4ea;
  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: auto;
`;

const InputContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  justify-content: space-between;
  margin-bottom: 20px;
  @media only screen and (min-width: 275px) and (max-width: 1100px) {
    flex-direction: column;
    width: 100%;
  }
`;

const StyledInput = styled.input`
  border-radius: 3px;
  width: 60%;
  height: 50px;
  background: white;
  border: 2px solid #333;
  font-family: "Nunito Sans";
  font-size: 20px;
  color: #333;
  padding-left: 20px;
  @media only screen and (min-width: 275px) and (max-width: 1100px) {
    width: 100%;
    margin-bottom: 32px;
  }
`;

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37%;
  height: 50px;
  border-radius: 3px;
  background-color: white;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  color: #f58320;
  border: 2px solid #333;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  @media only screen and (min-width: 275px) and (max-width: 1100px) {
    width: 100%;
    margin-bottom: 32px;
  }
`;

const Title = styled.h4`
  font-family: "Nunito Sans";
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;

const Success = styled.p`
  font-family: "Nunito Sans";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;

export default Newsletter;
