import React from "react";

class ErrorBoundary extends React.Component {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Sentry is initialized in Sentry browser if a DSN is set in the env. After init, Sentry should be set on window.

    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError || typeof window === "undefined") {
      return <div></div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
