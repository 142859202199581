import styled from "styled-components/macro";
import React from "react";

const Burger = ({ open, setOpen }) => {
  const isExpanded = open;

  return (
    <StyledBurger
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      open={open}
      onClick={() => setOpen(!open)}
    >
      <span />
      <span />
    </StyledBurger>
  );
};

export default Burger;

const StyledBurger = styled.button`
  @media screen and (min-width: 992px) {
    display: none;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2.6rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.1rem;
  z-index: 10;
  margin-right: 2rem;
  :focus {
    outline: 0 !important;
  }
  span {
    width: 2rem;
    height: 0.3rem;
    background: ${({ open }) => (open ? "#0D0C1D" : "#0D0C1D")};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    :nth-child(2) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;
