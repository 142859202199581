import React from "react";
import { Helmet } from "react-helmet";
import config from "../../data/SiteConfig";
import Sticky from "react-stickynode";
import "./index.css";
import Header from "../components/Navigation/Header";
import Newsletter from "../components/Newsletter";
import { Provider } from "react-redux";
import FooterComponent from "../components/Footer/Footer";
import store, { persistor } from "../store";
import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary from "../ErrorBoundry";
// import favicon from "../favicon.ico";

export default function MainLayout({ children, path }) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="layout-container">
          <Helmet>
            <meta name="description" content={config.siteDescription} />
            {/* <link rel="icon" href={favicon} /> */}
            <link
              rel="stylesheet"
              type="text/css"
              charset="UTF-8"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />
          </Helmet>
          <Sticky innerZ={1000}>
            <Header path={path} />
          </Sticky>
          <ErrorBoundary>{children}</ErrorBoundary>
          <Newsletter />
          <FooterComponent />
        </div>
      </PersistGate>
    </Provider>
  );
}
