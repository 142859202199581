import React, { useState } from "react";
import styled, { css } from "styled-components/macro";
import { ReactComponent as Logo } from "../../images/MayaLogo.svg";
import { connect } from "react-redux";
import ShoppingBasket from "./ShoppingBasket";
import { getTotalAmountOfProducts } from "../../reducers";
// import { injectIntl } from 'gatsby-plugin-intl';
import useMedia from "use-media";
import BurgerComponent from "./BurgerComponent";
import {
  Link,
  navigate,
  FormattedMessage,
  useIntl,
  changeLocale,
} from "gatsby-plugin-intl";

const Header = (props) => {
  const isDesktop = useMedia({ minWidth: "992px" });
  const [open, setOpen] = useState(false);
  const tabIndex = open ? 0 : -1;
  const pathname = props.path ?? "";
  const intl = useIntl();
  return (
    <Container>
      <InnerHeader>
        <StyledLogo onClick={() => navigate("/")} />
        {!isDesktop && <BurgerComponent setOpen={setOpen} open={open} />}
        <StyledMenu open={open} aria-hidden={open}>
          <StyledLink
            to="/vision/"
            activeStyles={{ borderBottom: "6px #007ec9 solid" }}
            tabIndex={isDesktop ? 1 : tabIndex}
          >
            <FormattedMessage id="vision" />
          </StyledLink>
          <StyledLink
            to="/projects/"
            style={
              pathname.includes("/projects")
                ? { borderBottom: "6px #007ec9 solid" }
                : {}
            }
            tabIndex={isDesktop ? 1 : tabIndex}
          >
            <FormattedMessage id="projects" />
          </StyledLink>

          <StyledLink
            to="/blog/"
            style={
              pathname.includes("/blog")
                ? { borderBottom: "6px #007ec9 solid" }
                : {}
            }
            tabIndex={isDesktop ? 1 : tabIndex}
          >
            <FormattedMessage id="blog" defaultMessage="Blog" />
          </StyledLink>

          <StyledLink
            to="/maya-home/"
            style={
              pathname.includes("/maya-home")
                ? { borderBottom: "6px #007ec9 solid" }
                : {}
            }
            tabIndex={isDesktop ? 1 : tabIndex}
          >
            <FormattedMessage id="mayaHome" defaultMessage="Maya Home" />
          </StyledLink>
          <StyledLink
            to="/about-us/"
            style={
              pathname.includes("/about-us")
                ? { borderBottom: "6px #007ec9 solid" }
                : {}
            }
            tabIndex={isDesktop ? 1 : tabIndex}
          >
            Team
          </StyledLink>
          {/* <StyledLink
            to="/sponsor/"
            style={
              pathname.includes("/sponsor")
                ? { borderBottom: "6px #007ec9 solid" }
                : {}
            }
            tabIndex={isDesktop ? 1 : tabIndex}
          >
            <FormattedMessage id="sponsor" defaultMessage="Sponsorartikelen" />
          </StyledLink> */}
          <StyledNavButton to="/donation/">
            <FormattedMessage id="donate" defaultMessage="Doneren" />
          </StyledNavButton>
          <LocaleSwitch
            onClick={() => changeLocale(intl.locale === "en" ? "nl" : "en")}
          >
            {intl.locale === "en" ? "Nederlands" : "English"}
          </LocaleSwitch>
          {props.products > 0 && (
            <NavItem to="/sponsor/checkout">
              <ShoppingBasket amount={props.products} />
            </NavItem>
          )}
        </StyledMenu>
      </InnerHeader>
    </Container>
  );
};

const Container = styled.div`
  /* background-color: ${theme.colors.greenBackground}; */
  opacity: 1;
  height: 84px;
  z-index: 5;
  max-width: 100vw;
  transition: background 0.3s ease-out;
  transition: box-shadow 0.3s ease-out;
  background: white;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.24);
`;

const InnerHeader = styled.div`
  display: flex;
  margin: auto;
  height: 100%;
  width: 100vw;
  background: white;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({ isScrolled }) =>
    isScrolled &&
    css`
      background: white;
    `}
  padding-left: 10px;
  @media (min-width: 992px) {
    padding-left: 3.6rem;
    padding-right: 3.6rem;
  }

  @media (min-width: 1280px) {
    padding-left: 0;
    padding-right: 0;
    max-width: 1200px;
    width: 1200px;
    margin: auto;
  }
`;

const NavItem = styled(Link)`
  width: 50px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: none;
`;

const StyledLink = styled(Link)`
  font-family: "Nunito Sans";
  font-style: normal;
  font-size: 16px;
  height: 100%;
  line-height: 35px;
  align-content: center;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  color: #333333;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  @media (min-width: 992px) {
    display: flex;
    transform: none;
    flex-direction: row;
    position: relative;
    margin-bottom: 0px;
  }
`;

const LocaleSwitch = styled.div`
  font-family: "Nunito Sans";
  font-style: normal;
  font-size: 16px;
  height: 100%;
  line-height: 35px;
  align-content: center;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  color: #3b89ca;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (min-width: 992px) {
    display: flex;
    transform: none;
    flex-direction: row;
    position: relative;
    margin-bottom: 0px;
  }
`;

export const StyledMenu = styled.nav`
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  position: absolute;
  top: 70px;
  left: 0;
  display: flex;
  padding-top: 205px;
  padding-bottom: 405px;
  background: white;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  transition: transform 0.3s ease-in-out;

  @media (min-width: 992px) {
    display: flex;
    transform: none;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    width: 950px;
    top: 0px;
    float: right;
    left: auto;
    padding: 0;
    right: 0;
    background: transparent;
    align-items: center;
    height: 100%;
  }
`;

const StyledLogo = styled(Logo)`
  cursor: pointer;
`;

const StyledNavButton = styled(Link)`
  font-family: "Nunito Sans";
  font-style: normal;
  font-size: 16px;
  height: 100%;
  line-height: 35px;
  align-content: center;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  color: #333333;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  @media (min-width: 1280px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 105px;
    height: 35px;
    border-radius: 3px;
    background-color: #f58320;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    color: white;
    margin-bottom: 0px;
    border-width: 0px;
    position: relative;
    text-decoration: none;
  }
`;
const mapStateToProps = (state) => ({
  products: getTotalAmountOfProducts(state),
});

export default connect(mapStateToProps)(Header);
